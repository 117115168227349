import axios from "axios"

const instance = axios.create({
	baseURL: import.meta.env.VITE_BASE_API_URL + "/",
	headers: {
		"X-Requested-With": "XMLHttpRequest",
	},
	withCredentials: true,
})

export default instance
